import { useContext, useEffect, useState } from "react";
import { createSearchParams, useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { apiRequest } from "src/async/apiUtils";
import { logoutRedirect } from "src/async/fetch";
import Preloader from "src/components/shared/LoadingAnimations/Preloader";
import { AuthContext } from "src/context/auth";
import Dashboardlayout from "src/layouts/DashboardLayout";

const ProtectedRoute = () => {
  const [publicGroup, setPublicGroup] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const { user, setUser } = useContext(AuthContext);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { workspace_id } = useParams();
  const params = { redirectUrl: `${location.pathname}${location.search}` };
  const queryString = `?${createSearchParams(params)}`;

  const loadUser = async () => {
    if (user?._id) {
      setIsLoaded(true);
      return;
    }

    if (localStorage.getItem("accessToken")) {
      try {
        const { data: resData } = await apiRequest("post", "/portal/users/self/details", {
          body: { token: localStorage.getItem("accessToken"), access_token: localStorage.getItem("accessToken") },
        });

        if (resData.status !== 200) logoutRedirect();
        setUser(resData.data);
      } catch (error) {
        if (error.message !== "Failed to fetch" && error.message !== "The user aborted a request.") logoutRedirect();
      } finally {
        setIsLoaded(true);
      }
    } else {
      navigate({ pathname: "/", search: queryString });
    }
  };

  const getPublicGroupDetails = async () => {
    try {
      const { data: resData } = await apiRequest("get", "/portal/workspaces/:workspace_id/groups/:group_id/pages/:page_id/public", {
        params: { workspace_id, group_id: searchParams.get("group"), page_id: searchParams.get("page") },
      });

      if (resData?.status === 200) {
        setPublicGroup(true);
      } else {
        setPublicGroup(false);
        loadUser();
      }
    } catch (error) {
      setPublicGroup(false);
      if (error.message !== "Failed to fetch" && error.message !== "The user aborted a request.") loadUser();
    } finally {
      setIsLoaded(true);
    }
  };

  useEffect(() => {
    if (location?.pathname?.includes("/embed") && workspace_id && searchParams.get("group") && searchParams.get("page")) {
      getPublicGroupDetails();
    } else {
      loadUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?._id, location?.pathname, workspace_id, searchParams]);

  if (window.location.pathname.includes("/embeded/") || window.location.pathname.includes("/embed/items/")) {
    return <Dashboardlayout nested />;
  }

  if (!isLoaded)
    return (
      <div className="flex h-screen w-full items-center justify-center pt-16">
        <Preloader />
      </div>
    );

  return <Dashboardlayout publicGroup={publicGroup} />;
};

export default ProtectedRoute;
